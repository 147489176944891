//css
import './app.scss';
import './css/bootstrap-grid.css';
import './css/colorbox.css'; 
//import './css/all.min.css'; 

// import '@fortawesome/fontawesome-svg-core';
// import '@fortawesome/free-solid-svg-icons';
// import '@fortawesome/free-regular-svg-icons';
// import '@fortawesome/free-brands-svg-icons';

//JS
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'jquery-colorbox';

import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

const splide = new Splide( '.splide', {
    type   : 'loop',
    drag   : 'free',
    focus  : 0,
    perPage: 3,
    pagination:false,
    arrows: false,
    autoScroll: {
      speed: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
    },
    pauseOnHover: false,
    pauseOnFocus: false,
    breakpoints: {
      767 : { perPage: 1},
    },
});
splide.mount({ AutoScroll });

import { TimelineMax, TweenMax, Linear } from 'gsap';
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax, Linear);

var controller = new ScrollMagic.Controller();
const parallaxParent = document.querySelectorAll(".parallaxParent");
parallaxParent.forEach(function(self) {
  let parallaxIMG =   self.querySelector('.parallax_img');
  // build
  new ScrollMagic.Scene({triggerElement: self,triggerHook: "onEnter", duration: "200%"})
  .setTween(TweenMax.to(parallaxIMG, 0.2, {y: "80%", ease: Linear.easeNone, immediateRender: false}))
  .addTo(controller);
});
 
import AOS from 'aos';
AOS.init();

import { humberger } from './js/menu';
humberger();